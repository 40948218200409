<template>

    <div class="box">
        <h2 style="text-align:left">Metro Dashboard</h2>
            <el-form label-position="top" label-width="100px" :model="login_form">
                <el-form-item label="Username">
                    <el-input v-model="login_form.username" @keyup.enter="login" size="large"/>
                </el-form-item>
                <el-form-item label="Password">
                    <el-input v-model="login_form.password" type="password" @keyup.enter="login" size="large"/>
                </el-form-item>
            </el-form>
            <el-button type="primary" style="width:100%" @click="login" size="large">Login Dashboard</el-button>
    </div>
</template>


<script>
    import {
        login_service
    } from '../services/requests.js'
    import { ElMessage } from 'element-plus'

    export default {
        data() {
            return {
                login_form: {
                    username: "",
                    password: ""
                }
            }
        },
        methods: {
            login(){
                if(this.login_form.username.length ==0){
                    ElMessage.error('username not null')
                    return
                }
                if(this.login_form.password.length ==0){
                    ElMessage.error('password not null')
                    return
                }
                login_service(this.login_form).then(
                    res=>{
                        var resp = res.data
                        if(resp.code == 200){
                            console.log("Login Success", resp)
                            var token = resp.token;
                            this.$cookies.set("metro-token", token, -1) 
                            ElMessage.success("Login Success")
                            setTimeout(()=>{
                                this.$router.push("/dashboard")
                            }, 500)
                        }
                        else{
                            ElMessage.error("Login Failed")
                        }
                    }
                )
            }
            
        },
        mounted(){
            document.body.style.zoom = "100%";
        }
    }
</script>



<style>
    .box {
        width: 360px;
        margin: 0 auto;
        margin-top: 100px;
    }

</style>